<app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>
<div class="container-body" *ngIf="listUserResponse != null && listUserResponse.listUser != null">
  <div class="col-4">
    <button class="button-back" (click)="backPage()">
      <mat-icon class="icon-back">keyboard_arrow_left</mat-icon>
      <span>Voltar</span>
    </button>
  </div>
  <div class="row">
    <div class="white-body">
      <div class="row">
        <h2>Usuários da Oficina</h2>
        <hr>
      </div>
      <div class="row search">
        <div class="col-md-4" [formGroup]="model">
          <mat-form-field appearance="outline">
            <mat-label>Procurar</mat-label>
            <input formControlName="searchText" matInput type="text" (keyup.enter)="searchUser()">
            <button matSuffix mat-icon-button (click)="searchUser()">
              <mat-icon>search</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <div class="col-md-6 buttonUser">
          <button mat-button color="primary" (click)="openDialogNew()"
            type="button">
            <mat-icon class="material-symbols-outlined icon-add">add</mat-icon>
            Cadastrar Usuário
          </button>
        </div>
      </div>
      <div class="row table-responsive" *ngIf="screenWidth >= 650">
        <table class="basic-table table-color">
          <thead class="basic-table__head">
            <tr class="truncate">
              <th>Nome</th>
              <th>Função</th>
              <th>Telefone</th>
              <th>Email</th>
              <!-- <th>Status</th> -->
              <th>Ações</th>
              <th></th>
            </tr>
          </thead>
          <tbody class="basic-table__body" *ngFor="let item of listUserResponse.listUser">
            <!-- INICIO MOCK -->
            <tr class="basic-table__body__row">
              <td class="truncate">{{item.userName}}</td>
              <td class="truncate">{{item.job}}</td>
              <td class="truncate">{{item.phone}}</td>
              <td class="truncate">{{item.email}}</td>
              <!-- <td class="truncate">
                <mat-slide-toggle></mat-slide-toggle>
              </td> -->
              <td class="truncate">
                <button mat-icon-button aria-label="Example icon button with a home icon"
                  (click)="openDialogEdit(item)">
                  <mat-icon>launch</mat-icon>
                </button>
                <button mat-icon-button aria-label="Example icon button with a home icon"
                  (click)="openDialogDelete(item)">
                  <mat-icon>delete</mat-icon>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div *ngIf="screenWidth < 650">
        <div class="row card-spaccing" *ngFor="let item of listUserResponse.listUser">
          <div class="col-sm-6 w-100">
            <div class="card">
              <div class="card-body">
                <div class="row card-title">
                  <ul class="ul">
                    <li class="title">
                      <span class="second-title">Nome: </span>
                      <span>{{item.userName}}</span>
                    </li>
                    <li class="title">
                      <span class="second-title">Função: </span>
                      <span>{{item.job}}</span>
                    </li>
                  </ul>
                </div>
                <div class="row">
                  <ul>
                    <li class="title">
                      <span class="second-title">
                        Email:
                      </span>
                      <span>
                        {{item.email}}
                      </span>
                    </li>
                  </ul>
                </div>
                <div class="row button-card">
                  <div class="button-os">
                    <div class="button" mat-dialog-actions>
                      <button style="background-color: #001F38; color: white;" mat-button type="button"
                        class="btn-primary" color="primary" (click)="openDialogEdit(item)">
                        Editar
                      </button>
                      <button style="background-color: red; color: white;" mat-button type="button" class="btn-primary"
                        color="primary" (click)="openDialogDelete(item)">
                        Deletar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
