<app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

<app-search-header  [idServiceOrder]="idServiceOrder"  (OutputClickFilter)="clickFilter()" [modelSearch]="modelSearch" [getInfosResponse]="getInfosResponse" [plateNumber]="plateNumber" [year]="year"></app-search-header>


<div class="container-body" *ngIf="Aplication != null && Aplication != null">
  <div class="col-md-3">
    <button class="button-back">
      <mat-icon class="icon-back">keyboard_arrow_left</mat-icon>
      <span>Voltar</span>
    </button>
  </div>
  <div class="row product-section">
    <div class="card mb-4 div-product">
      <div class="row g-0">
        <div class="col-md-4">
          <img src="assets/images/search-plate.png" class="img-fluid rounded-start" alt="...">
        </div>
        <div class="col-md-8">
          <div class="card-body">
            <h2 class="card-title">Vela de Ignição - BOSCH SP16</h2>
            <p class="card-price">R$ 19,99</p>
            <span class="card-text">Fornecedor: AutoBits</span>
          </div>
          <!-- <form class="form" (ngSubmit)="clickAddItem()">
            <div class="amount">
              <span>Quantidade</span>
              <select formControlName="amount" class="col-md-1">
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
            </div>
            <a color="accent" type="button" class="btn btn-primary-blue" (click)="clickAddItem()">
              Adicionar ao carrinho
            </a>
          </form> -->
          <form class="form">
            <div class="amount">
              <span>Quantidade</span>
              <select class="col-md-1">
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
            </div>
            <a color="accent" type="button" class="btn btn-primary-blue">
              Adicionar ao carrinho
            </a>
          </form>
        </div>
      </div>
    </div>
    <div class="table">
      <div>
        <button (click)="setIsAplication()" [ngClass]="{'selected-tab':setAplication}" type="button" class="btn btn">
          <mat-icon>fullscreen</mat-icon>
          Aplicação
        </button>
      </div>
      <div>
        <button (click)="setIsSpecification()" [ngClass]="{'selected-tab':setSpecification}" type="button"
          class="btn btn">
          <mat-icon>assignment</mat-icon>
          Especificações Técnicas
        </button>
      </div>
      <div>
        <button (click)="setIsSimilares()" [ngClass]="{'selected-tab':setSimilares}" type="button" class="btn btn">
          <mat-icon> category</mat-icon>
          Similares
        </button>
      </div>
    </div>
    <table class="basic-table aplication overflow-auto" *ngIf="setAplication">
      <thead class="basic-table__head">
        <tr class="truncate">
          <th>Montadora</th>
          <th>Veículo</th>
          <th>Modelo</th>
          <th>Motor</th>
          <th>ConfigMotor</th>
          <th>Inicio</th>
          <th>Fim</th>
          <th></th>
        </tr>
      </thead>
      <tbody class="basic-table__body" *ngFor="let item of Aplication">
        <tr class="basic-table__body__row">
          <td class="truncate">{{item.Montadora}}</td>
          <td class="truncate">{{item.Veiculo}}</td>
          <td class="truncate">{{item.Modelo}}</td>
          <td class="truncate">{{item.Motor}}</td>
          <td class="truncate">{{item.ConfigMotor}}</td>
          <td class="truncate">{{item.Inicio}}</td>
          <td class="truncate">{{item.Fim}}</td>
          <td class="truncate"></td>
        </tr>
      </tbody>
    </table>
    <table class="basic-table Specification overflow-auto" *ngIf="setSpecification">
      <thead class="basic-table__head">
        <tr class="truncate">
          <th>Fiscal</th>
          <th></th>
        </tr>
      </thead>
      <tbody class="basic-table__body">
        <!-- INICIO MOCK -->
        <tr class="basic-table__body__row">
          <td class="truncate"><span>
              Código de barras (EAN)
            </span></td>
          <td class="truncate"><span>
              4047026226805
            </span></td>
          <td class="truncate"></td>
        </tr>
        <tr class="basic-table__body__row">
          <td class="truncate"><span>
              NCM
            </span></td>
          <td class="truncate"><span>
              8708.83.90
            </span></td>
          <td class="truncate"></td>
        </tr>
      </tbody>
      <thead class="basic-table__head">
        <tr class="truncate">
          <th>NCM</th>
          <th></th>
        </tr>
      </thead>
      <tbody class="basic-table__body">
        <!-- INICIO MOCK -->
        <tr class="basic-table__body__row">
          <td class="truncate"><span>
              8708.83.90
            </span></td>
          <td class="truncate"></td>
        </tr>
      </tbody>
      <thead class="basic-table__head">
        <tr class="truncate">
          <th>Garantia</th>
          <th></th>
        </tr>
      </thead>
      <tbody class="basic-table__body">
        <!-- INICIO MOCK -->
        <tr class="basic-table__body__row">
          <td class="truncate"><span>
              3 Meses
            </span></td>
          <td class="truncate"></td>
        </tr>
      </tbody>
      <thead class="basic-table__head">
        <tr class="truncate">
          <th>AlturaEmbalagem</th>
          <th></th>
        </tr>
      </thead>
      <tbody class="basic-table__body">
        <!-- INICIO MOCK -->
        <tr class="basic-table__body__row">
          <td class="truncate"><span>
              190 mm
            </span></td>
          <td class="truncate"></td>
        </tr>
      </tbody>
      <thead class="basic-table__head">
        <tr class="truncate">
          <th>ComprimentoEmbalagem</th>
          <th></th>
        </tr>
      </thead>
      <tbody class="basic-table__body">
        <!-- INICIO MOCK -->
        <tr class="basic-table__body__row">
          <td class="truncate"><span>
              257 mm
            </span></td>
          <td class="truncate"></td>
        </tr>
      </tbody>
      <thead class="basic-table__head">
        <tr class="truncate">
          <th>LarguraEmbalagem</th>
          <th></th>
        </tr>
      </thead>
      <tbody class="basic-table__body">
        <!-- INICIO MOCK -->
        <tr class="basic-table__body__row">
          <td class="truncate"><span>
              257 mm
            </span></td>
          <td class="truncate"></td>
        </tr>
      </tbody>
    </table>
    <table class="basic-table overflow-auto" *ngIf="setSimilares">
      <thead class="basic-table__head">
        <tr class="truncate">
          <th>MarcaSimilar</th>
          <th>CódigoSimilar</th>
          <th></th>
        </tr>
      </thead>
      <tbody class="basic-table__body">
        <!-- INICIO MOCK -->
        <tr class="basic-table__body__row">
          <td class="truncate"><span>BENDIX</span></td>
          <td class="truncate"><span>2253937</span></td>
          <td class="truncate"></td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
