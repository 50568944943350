import {Injectable} from '@angular/core';
import {PartProductStruct} from 'src/app/shared/services/API/structs/autoparts/part-product.struct';
import {AlertService, AlertType} from 'src/app/shared/services/alert.service';

@Injectable({
  providedIn: 'root'
})
export class SearchShoppingCartService {

  constructor(
    private alertService: AlertService
  ) {
  }

  private _storageKey = "shoppingCart";
  private _wishListKey = "wishList";

  getParts(): SearchShoppingCartStruct {
    return JSON.parse(sessionStorage.getItem(this._storageKey));
  }

  addPart(amount: number, partProductStruct: PartProductStruct): SearchShoppingCartStruct {

    let searchShoppingCartStuct: SearchShoppingCartStruct = JSON.parse(sessionStorage.getItem(this._storageKey));

    if (searchShoppingCartStuct == null) {
      searchShoppingCartStuct = new SearchShoppingCartStruct();
    }

    const searchShoppingCarItemStruct: SearchShoppingCarItemStruct = new SearchShoppingCarItemStruct();
    searchShoppingCarItemStruct.amount = parseInt((amount + ''));
    searchShoppingCarItemStruct.partProductStruct = partProductStruct;


    if (searchShoppingCartStuct.searchShoppingCarItemStruct == null || searchShoppingCartStuct.searchShoppingCarItemStruct == undefined) {
      searchShoppingCartStuct.searchShoppingCarItemStruct = []
    }

    // se a part não existir no carrinho{}
    if (searchShoppingCartStuct.searchShoppingCarItemStruct.some(c => (c.partProductStruct.idPart == searchShoppingCarItemStruct.partProductStruct.idPart))) {
      for (let i = 0; i < searchShoppingCartStuct.searchShoppingCarItemStruct.length; i++) {

        if (searchShoppingCartStuct.searchShoppingCarItemStruct[i].partProductStruct.idPart == searchShoppingCarItemStruct.partProductStruct.idPart) {
          const totalAmount = parseInt(searchShoppingCartStuct.searchShoppingCarItemStruct[i].amount + '') + parseInt(searchShoppingCarItemStruct.amount + '')
          if (totalAmount > searchShoppingCarItemStruct.partProductStruct.quantity) {
            this.alertService.show('Alerta', "Você já adicionou a quantidade máxima deste item no carrinho!", AlertType.warning);
            return searchShoppingCartStuct;
            // console.log("quantity: " , searchShoppingCarItemStruct.partProductStruct.quantity, "\n AMOUNT: ", parseInt(searchShoppingCartStuct.searchShoppingCarItemStruct[i].amount+ ''), "\n TOTAL: ", totalAmount)
          } else {
            searchShoppingCartStuct.searchShoppingCarItemStruct[i].amount = totalAmount
          }
        }

      }
    } else {
      searchShoppingCartStuct.searchShoppingCarItemStruct.push(searchShoppingCarItemStruct);
    }


    searchShoppingCartStuct = this.updateTotal(searchShoppingCartStuct);

    sessionStorage.setItem(this._storageKey, JSON.stringify(searchShoppingCartStuct));
    return searchShoppingCartStuct;
  }


  clearParts(): SearchShoppingCartStruct {
    sessionStorage.removeItem(this._storageKey);
    return null;
  }

  deleteaddPart(idPart: number, searchShoppingCartStuct: SearchShoppingCartStruct): SearchShoppingCartStruct {

    if (searchShoppingCartStuct == null || searchShoppingCartStuct.searchShoppingCarItemStruct == null)
      return searchShoppingCartStuct;

    searchShoppingCartStuct.searchShoppingCarItemStruct = searchShoppingCartStuct.searchShoppingCarItemStruct.filter(c => (c.partProductStruct.idPart != idPart));

    searchShoppingCartStuct = this.updateTotal(searchShoppingCartStuct);
    sessionStorage.setItem(this._storageKey, JSON.stringify(searchShoppingCartStuct));
    return searchShoppingCartStuct;
  }

  changeAmountPart(idPart: number, amount: number, searchShoppingCartStuct: SearchShoppingCartStruct): SearchShoppingCartStruct {
    if (searchShoppingCartStuct == null || searchShoppingCartStuct.searchShoppingCarItemStruct == null)
      return searchShoppingCartStuct;
    const index = searchShoppingCartStuct.searchShoppingCarItemStruct.findIndex(item => item.partProductStruct.idPart === idPart);

    if (index !== -1) {
      searchShoppingCartStuct.searchShoppingCarItemStruct[index].amount = amount;

      searchShoppingCartStuct = this.updateTotal(searchShoppingCartStuct);
      sessionStorage.setItem(this._storageKey, JSON.stringify(searchShoppingCartStuct));
    }

    return searchShoppingCartStuct;
  }

  updateTotal(searchShoppingCartStruct: SearchShoppingCartStruct): SearchShoppingCartStruct {

    if (searchShoppingCartStruct == null || searchShoppingCartStruct.searchShoppingCarItemStruct == null)
      return searchShoppingCartStruct;

    let total = 0;
    let items = 0;
    for (let i = 0; i < searchShoppingCartStruct.searchShoppingCarItemStruct.length; i++)
    {
      total = total + (searchShoppingCartStruct.searchShoppingCarItemStruct[i].partProductStruct.price * searchShoppingCartStruct.searchShoppingCarItemStruct[i].amount);
      items += searchShoppingCartStruct.searchShoppingCarItemStruct[i].amount;
    }

    searchShoppingCartStruct.total = total;
    searchShoppingCartStruct.items = items;

    return searchShoppingCartStruct;
  }

  //WISHLIST
  getWishListParts(): SearchShoppingCartStruct {
    return JSON.parse(sessionStorage.getItem(this._wishListKey));
  }

  addWishListPart(amount: number, partProductStruct: PartProductStruct): SearchShoppingCartStruct {

    let searchShoppingCartStruct: SearchShoppingCartStruct = JSON.parse(sessionStorage.getItem(this._wishListKey));

    if (searchShoppingCartStruct == null) {
      searchShoppingCartStruct = new SearchShoppingCartStruct();
    }

    const searchShoppingCarItemStruct: SearchShoppingCarItemStruct = new SearchShoppingCarItemStruct();
    searchShoppingCarItemStruct.amount = amount;
    searchShoppingCarItemStruct.partProductStruct = partProductStruct;


    if (searchShoppingCartStruct.searchShoppingCarItemStruct == null || searchShoppingCartStruct.searchShoppingCarItemStruct == undefined) {
      searchShoppingCartStruct.searchShoppingCarItemStruct = []
    }

    searchShoppingCartStruct.searchShoppingCarItemStruct.push(searchShoppingCarItemStruct);

    searchShoppingCartStruct = this.updateTotal(searchShoppingCartStruct);

    sessionStorage.setItem(this._wishListKey, JSON.stringify(searchShoppingCartStruct));

    return searchShoppingCartStruct;
  }

  deleteWishListPart(idPart: number, searchShoppingCartStuct: SearchShoppingCartStruct): SearchShoppingCartStruct {

    if (searchShoppingCartStuct == null || searchShoppingCartStuct.searchShoppingCarItemStruct == null)
      return searchShoppingCartStuct;

    searchShoppingCartStuct.searchShoppingCarItemStruct = searchShoppingCartStuct.searchShoppingCarItemStruct.filter(c => (c.partProductStruct.idPart != idPart));

    searchShoppingCartStuct = this.updateTotal(searchShoppingCartStuct);
    sessionStorage.setItem(this._wishListKey, JSON.stringify(searchShoppingCartStuct));
    return searchShoppingCartStuct;
  }
}


export class SearchShoppingCartStruct {
  public searchShoppingCarItemStruct: SearchShoppingCarItemStruct[];
  public total: number;
  public items: number;
}

export class SearchShoppingCarItemStruct {
  amount: number;
  partProductStruct: PartProductStruct;
}
