<app-menu #menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'
  [hasSecondaryHeader]=false></app-menu>

<div class="container-body">
  <div class="row">
    <!-- <div class="white-body-shopping col">
      <div class="icons-white-body">
        <span>Compras em Trânsito</span>
        <h2>5</h2>
      </div>
      <mat-icon class="local_shipping_icon"> local_shipping</mat-icon>
    </div>
    <div class="white-body-shopping col middle-body">
      <div class="icons-white-body">
        <span>Carrinho Favoritos</span>
        <h2>10</h2>
      </div>
      <mat-icon class="shopping_cart_icon">shopping_cart</mat-icon>
    </div> -->
    <div class="white-body-shopping col">
      <div class="icons-white-body">
        <span>Compras do Mês</span>
        <h2>{{this.lengthMonth}}</h2>
      </div>
      <!-- <span class="material-symbols-outlined package_2_icon">package_2</span> -->
      <mat-icon class="package_2_icon">inbox</mat-icon>
    </div>
  </div>
  <div class="row">
    <div class="white-body">
      <div class="row">
        <h2>Minhas compras</h2>
        <hr>
      </div>
      <div class="row" [formGroup]="model">
        <div class="col-md-4">
          <mat-form-field>
            <mat-label>Insira um período</mat-label>
            <mat-date-range-input [rangePicker]="picker">
              <input matStartDate formControlName="start" placeholder="Data Início">
              <input matEndDate formControlName="end" placeholder="Data Final">
            </mat-date-range-input>
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field appearance="outline">
            <mat-label>Pesquisar ID</mat-label>
            <input formControlName="text" matInput type="text" (keyup.enter)="populatePage()">
            <button matSuffix mat-icon-button aria-label="Clear" (click)="populatePage()">
              <mat-icon>search</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field appearance="outline">
            <mat-label>Status</mat-label>
            <mat-select formControlName="status">
              <mat-option value="0">Todos</mat-option>
              <mat-option value="1">Pedido em análise</mat-option>
              <mat-option value="2">Pedido aceito</mat-option>
              <mat-option value="3">Mercadoria em trânsito</mat-option>
              <mat-option value="4">Concluído</mat-option>
              <mat-option value="5">Pedido negado</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-4 button">
          <button mat-button color="primary" (click)="clearFilter()">Limpar Filtro</button>
        </div>
      </div>
      <div class="row table-responsive" *ngIf="loading && screenWidth >= 650">
        <table class="basic-table table-color" *ngIf="getPurchaseHistoryResponse != null">
          <thead class="basic-table__head">
            <tr class="truncate">
              <th></th>
              <th>ID</th>
              <th>Data da Compra</th>
              <th>Nº de Produtos</th>
              <th>Status</th>
              <th>Total</th>
              <th></th>
            </tr>
          </thead>
          <tbody class="basic-table__body" *ngFor="let item of slicedData">
            <tr class="basic-table__body__row" (click)="toggleDetails(item)">
              <td class="truncate">
                <mat-icon>{{ item.isDetails ? 'expand_more' : 'chevron_right' }}</mat-icon>
              </td>
              <td class="truncate">{{item.idPurchase}}</td>
              <td class="truncate">{{item.datetimeInclusion | date: 'd MMMM y, HH:mm'}}</td>
              <td class="truncate">{{item.totalPartQuantity}}</td>
              <td class="truncate">
                <mat-chip-listbox>
                  <mat-chip style="background: #FFB800;" *ngIf="item.idPurchaseStatus === 1">
                    {{item.purchaseStatusName}}</mat-chip>
                  <mat-chip style="background: #3399FF;"
                    *ngIf="item.idPurchaseStatus === 3 || item.idPurchaseStatus == 6 || item.idPurchaseStatus === 2">
                    {{item.purchaseStatusName}}</mat-chip>
                  <mat-chip style="background: red;"
                    *ngIf="item.idPurchaseStatus === 5">
                    {{item.purchaseStatusName}}</mat-chip>
                  <mat-chip style="background: #038E49;" *ngIf="item.idPurchaseStatus === 4">
                    {{item.purchaseStatusName}}</mat-chip>
                </mat-chip-listbox>
              </td>
              <td class="truncate">{{item.totalPrice + item.deliveryPrice | currency : 'BRL'}}</td>
              <td class="truncate">
                <div class="open-purchase-container">
                  <a class="open-purchase" [routerLink]="['/workshop/purchase-made', item.idPurchase]">
                    <mat-icon style="color: #001F38;">arrow_circle_right</mat-icon>
                  </a>
                </div>
              </td>
            </tr>
            <tr class="basic-table__head second-head" *ngIf="item.isDetails">
              <td colspan="9">
                <table class="basic-table table-color">
                  <thead class="">
                    <tr class="truncate">
                      <th>Produto</th>
                      <!-- <th>Part Number</th> -->
                      <th>Marca</th>
                      <th>Fornecedor</th>
                      <th>Preço Unitário</th>
                      <th>Quantidade</th>
                    </tr>
                  </thead>
                  <tbody class="truncate">
                    <tr class="basic-table__body__row" *ngFor="let details of item.listBuyOrderPart">
                      <td class="truncate">{{details.partName}}</td>
                      <!-- <td class="truncate">{{details.partNumber}}</td> -->
                      <td class="truncate">{{details.brandName}}</td>
                      <td class="truncate">{{details.supplierName}}</td>
                      <td class="truncate">{{details.partPrice | currency: 'BRL'}}</td>
                      <td class="truncate">{{details.quantity}}</td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
        <mat-paginator #paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 25, 100]"
          (page)="sliceData($event)"> </mat-paginator>
      </div>
      <div *ngIf="loading && getPurchaseHistoryResponse != null && screenWidth < 650">
        <div class="row card-spaccing" *ngFor="let item of slicedData">
          <div class="col-sm-6 w-100">
            <div class="card">
              <div class="card-body">
                <div class="row card-title">
                  <ul class="ul">
                    <li class="title">
                      <span class="id">ID</span>
                      <span>{{item.idPurchase}}</span>
                    </li>
                    <li>
                      <span>{{item.datetimeInclusion | date: 'd MMMM y, HH:mm'}}</span>
                    </li>
                  </ul>
                </div>
                <div class="row">
                  <ul>
                    <li>
                      <span class="second-title">
                        Itens: 
                      </span>
                      <span class="title">
                        {{item.totalPartQuantity}}
                      </span>
                    </li>
                  </ul>
                </div>
                <div class="row">
                  <ul class="ul">
                    <li>
                      <mat-chip-listbox>
                        <mat-chip style="background: #FFB800;" *ngIf="item.idPurchaseStatus === 1">
                          {{item.purchaseStatusName}}</mat-chip>
                        <mat-chip style="background: #3399FF;"
                          *ngIf="item.idPurchaseStatus === 3 || item.idPurchaseStatus == 6 || item.idPurchaseStatus === 2">
                          {{item.purchaseStatusName}}</mat-chip>
                        <mat-chip style="background: red;"
                          *ngIf="item.idPurchaseStatus === 5">
                          {{item.purchaseStatusName}}</mat-chip>
                        <mat-chip style="background: #038E49;" *ngIf="item.idPurchaseStatus === 4">
                          {{item.purchaseStatusName}}</mat-chip>
                      </mat-chip-listbox>
                    </li>
                    <li>
                      <span class="title-price">
                        {{item.totalPrice | currency: 'BRL'}}
                      </span>
                    </li>
                  </ul>
                </div>
                <div class="row a-card">
                  <div class="button-os">
                    <a class="open-purchase" [routerLink]="['/workshop/purchase-made', item.idPurchase]">
                      <span style="color: #ffff;">VER COMPRA</span>
                      <mat-icon style="color: #ffff;">chevron_right</mat-icon>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="spinner" *ngIf="!modalAberto">
        <mat-progress-spinner *ngIf="loading" mode="indeterminate"></mat-progress-spinner>
      </div>
    </div>
  </div>
</div>
