
<div class="conteudo-delivery">
    
    
    <div class="row">
        <div class="col-md-6">
            <span class="span-title">{{supplier.branchName}}</span>
        </div>
        <div class="col-3 text-center"><span>Prazo de entrega</span></div>
        <div class="col-3 text-center"><span>Valor</span></div>
    </div>
    <hr>
    <form [formGroup]="model">
        <mat-radio-group aria-label="Select an option" formControlName="idDelivery" (change)="onChange()">
            <div class="row" *ngFor="let item of supplier.listSupplierDeliveryType">
                <div class="col-md-6">
                    <mat-radio-button value="{{item.idDeliveryType}}">{{item.deliveryTypeName}}</mat-radio-button>
                </div>
                <div class="col-md-3 text-center">
                    <div class="vertical-aling-out">
                        <div class="vertical-aling-inner">
                            <label>{{item.estimatedTimeArrival ? item.estimatedTimeArrival : 'Sem estimativa'}}</label>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 text-center">
                    <div class="vertical-aling-out">
                        <div class="vertical-aling-inner">
                            <a>{{item.totalDeliveryFee ? this.formatedSelectedDeliveryPrice : 'Sem estimativa'}}</a>
                        </div>
                    </div>
                </div>
            </div>            
        </mat-radio-group>
    </form>
</div>
