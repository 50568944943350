import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SupplierBranchStruct } from 'src/app/shared/services/API/structs/orchestrator-purchase/supplier-branch.struct';
import { BusinessDeliveryTypeStruct } from 'src/app/shared/services/API/structs/orchestrator-purchase/business-delivery-type.struct';
import { SupplierStruct } from 'src/app/shared/services/API/structs/orchestrator-purchase/supplier.struct';

@Component({
  selector: 'app-partner-delivery',
  templateUrl: './partner-delivery.component.html',
  styleUrls: ['./partner-delivery.component.css']
})
export class PartnerDeliveryComponent {

  constructor(private formBuilder: FormBuilder){

  }
  
  public model: FormGroup;

  @Input() supplier:SupplierBranchStruct;
  @Output() change = new EventEmitter<any>();
  public formatedSelectedDeliveryPrice: string;

  //Receber lista de entregas possiveis para aquele parceiro
  
  ngOnInit(): void {
    this.model = this.formBuilder.group({
      idDelivery: [null, [Validators.required]],
    });
    var merosDelivery = this.supplier.listSupplierDeliveryType.filter(x => x.idDeliveryType == 1)[0].totalDeliveryFee
    this.formatedSelectedDeliveryPrice =  new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(merosDelivery)
  }

  onChange(){
    //Dar emmit para tela principal enviando a entrega selecionada e o id do parceiro
    let selected: BusinessDeliveryTypeStruct = this.supplier.listSupplierDeliveryType.find(x => x.idDeliveryType ==  this.model.get("idDelivery").value)
    this.change.emit(selected)
  }
}
