import { Component, OnInit, HostListener } from '@angular/core';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { ListSupplierOrdersResponse } from 'src/app/shared/services/API/responses/orchestrator-purchase/list-supplier-orders.response';
import { PurchaseHistoryService } from 'src/app/shared/services/API/services/orchestrator-purchase/purchase-history.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SupplierOrderStruct } from 'src/app/shared/services/API/structs/orchestrator-purchase/supplier-order.struct';
import { ListPurchaseOrderRequest } from 'src/app/shared/services/API/requests/orchestrator-purchase/list-purchase-order.request';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OrderDetailsModalComponent } from 'src/app/supplier/pages/order-details-modal/order-details-modal.component';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-supplier-order',
  templateUrl: './supplier-order.component.html',
  styleUrls: ['./supplier-order.component.css']
})
export class SupplierOrderComponent implements OnInit {

 
  constructor(public purchaseHistoryService:PurchaseHistoryService,
    public alertService:AlertService,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialog
    ) { 
      this.onResize();
    }

  menuFunctionalityEnum: MenuFunctionalityEnum
  menuModuleEnum: MenuModuleEnum

  public listSupplierOrdersResponse:ListSupplierOrdersResponse;
  public listSupplierOrdersResponseHistory:ListSupplierOrdersResponse;
  public listSupplierOrdersResponseFinished:ListSupplierOrdersResponse;
  public listPurchaseOrderRequest: ListPurchaseOrderRequest;
  public isLoading:boolean = false;
  public model: FormGroup;
  public statusFormGroup : FormGroup;
  public populateInterval;
  public screenWidth: number;
  public isPendingOrders: boolean = true

  ngOnInit(): void {
      this.menuModuleEnum = MenuModuleEnum.fornecedor;
      this.menuFunctionalityEnum = MenuFunctionalityEnum.fornecedor_pedido;

      this.model = this.formBuilder.group({
        start: [null],
        end: [null],
        status: [null],
        text: [null],
        idSupplier: [null],
      });

      this.populateInterval = setInterval(() => {
        this.populate();
        this.populateFinished();
      }, 30000);

      this.model.get('status').valueChanges.subscribe(newValue => {
        this.populate();
        this.populateFinished();
      });
      this.model.get('start').valueChanges.subscribe(newValue => {
        this.populate();
        this.populateFinished();
      });
      this.model.get('end').valueChanges.subscribe(newValue => {
        this.populate();
        this.populateFinished();
      });

      this.populate();
      this.populateFinished();
  }

  ngOnDestroy(){
    clearInterval(this.populateInterval);
  }

  setRefrash(){
    this.populate();
    this.populateFinished();
  }

  setIsOrders(){
    this.isPendingOrders = true;
  }

  setIsFinishedOrders(){
    this.isPendingOrders = false;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenWidth = window.innerWidth;
  }

  clearFields() {
    this.model.get('start').setValue(null);
    this.model.get('end').setValue(null);
    this.model.get('text').setValue(null);
    this.model.get('status').setValue(null);
  }

  clearFilter(){
    this.clearFields();

    setTimeout(() => {
      this.populate();
    }, 1000);
  }

  populate(){
    if(this.isLoading) return;
    this.isLoading = true;

    const listPurchaseOrderRequest: ListPurchaseOrderRequest = {
      minDateTimeOrder: this.model.get('start').value,
      maxDateTimeOrder: this.model.get('end').value,
      textSearch: this.model.get('text').value,
      idBuyOrderStatus: this.model.get('status').value,
      idSupplier: null,
    };

    this.purchaseHistoryService.Post(listPurchaseOrderRequest).subscribe({
      next: (listSupplierOrdersResponse: ListSupplierOrdersResponse) => {

        if (listSupplierOrdersResponse.isError) {
          this.alertService.show('Erro', listSupplierOrdersResponse.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.isLoading = false;
        this.listSupplierOrdersResponse = listSupplierOrdersResponse;
        this.listSupplierOrdersResponseHistory = listSupplierOrdersResponse;        
        this.listSupplierOrdersResponse.listSupplierOrder.sort((a, b) => {
          return b.idBuyOrder ? -1: 1;
        });

        //ToDo:
        //this.listSupplierOrdersResponse.listSupplierOrder = this.listSupplierOrdersResponse.listSupplierOrder.find(c=> c.idPaymentStatus == 1);
        //this.listSupplierOrdersResponseHistory = this.listSupplierOrdersResponse; 

        // console.log(this.listSupplierOrdersResponse);
        
      },
      error: (error) => {
        console.error(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      }
    });
  }

  populateFinished(){

    this.isLoading = true;

    const listPurchaseOrderRequest: ListPurchaseOrderRequest = {
      minDateTimeOrder: this.model.get('start').value,
      maxDateTimeOrder: this.model.get('end').value,
      textSearch: this.model.get('text').value,
      idBuyOrderStatus: this.model.get('status').value,
      idSupplier: null,
    };

    this.purchaseHistoryService.PostFinished(listPurchaseOrderRequest).subscribe({
      next: (listSupplierOrdersResponse: ListSupplierOrdersResponse) => {

        if (listSupplierOrdersResponse.isError) {
          this.alertService.show('Erro', listSupplierOrdersResponse.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.isLoading = false;
        this.listSupplierOrdersResponseFinished = listSupplierOrdersResponse;
        
        this.listSupplierOrdersResponse.listSupplierOrder.sort((a, b) => {
          return b.orderDateTimeInclusion ? -1: 1;
        });

        //ToDo:
        //this.listSupplierOrdersResponse.listSupplierOrder = this.listSupplierOrdersResponse.listSupplierOrder.find(c=> c.idPaymentStatus == 1);
        //this.listSupplierOrdersResponseHistory = this.listSupplierOrdersResponse; 

        // console.log(this.listSupplierOrdersResponse);
        
      },
      error: (error) => {
        console.error(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      }
    });
  }

  modalDetails(item: any): void {
    if(this.screenWidth >= 650){
      const dialogRef = this.dialogRef.open(OrderDetailsModalComponent,{
        height: 'max-content',
        width: '100%',
        maxHeight: '90vh',
        data: {
          idBuyOrder: item.idBuyOrder,
        }
      })
      dialogRef.afterClosed().subscribe(() => {
        this.populate();
        this.populateFinished();
      });
    }else{
      const dialogRef = this.dialogRef.open(OrderDetailsModalComponent, {
        width: '100vw',
        height: '100vh',
        maxWidth: '100vw',
        maxHeight: '100vh',
        data: {
          idBuyOrder: item.idBuyOrder,
        }
      });
      dialogRef.afterClosed().subscribe(() => {
        this.populate();
        this.populateFinished();
      });
    }
  }
}
