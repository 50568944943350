import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, retry } from 'rxjs/operators';
import { CommonService } from '../../../common-service';
import { ListServiceOrderResponse } from '../../responses/serviceOrder/list-service-order.response';
import { SearchPartsRequest } from '../../requests/autoparts/search-parts.request';
import { SearchPartsResponse } from '../../responses/autoparts/Parts/search-parts.response';



@Injectable({
  providedIn: 'root'
})
export class PartsService extends CommonService {

  constructor(
    private httpClient: HttpClient) {
    super();
  }

  public searchParts(body: SearchPartsRequest): Observable<SearchPartsResponse> {

    const url = `Parts/SearchPartsSupplier`

    return this.httpClient.post<SearchPartsResponse>(environment.urlApiAutoParts + url, body, this.addHeaderToken())
    .pipe(
      catchError(this.handleError),
      retry(3)
    )
  }
}
