<div class="shopping-card"
  *ngIf="searchShoppingCartStuct != null && searchShoppingCartStuct.searchShoppingCarItemStruct != null && searchShoppingCartStuct.searchShoppingCarItemStruct.length > 0">
  <h1>Carrinho</h1>

  <div class="container-bottom">
    <div class="total">
      <div class="item-row">
        <div class="item-col-2">
          <h2>Total</h2>
        </div>
        <div class="item-col-2">
          <div class="text-right">
            <h2> {{searchShoppingCartStuct.total | currency: 'BRL'}}</h2>
          </div>
        </div>
      </div>
      <div class="item-row">
        <div class="item-col-2">
          <h3>Itens</h3>
        </div>
        <div class="item-col-2">
          <div class="text-right">
            <h2> {{searchShoppingCartStuct.items}}</h2>
          </div>
        </div>
      </div>
    </div>

    <div class="action">
      <!-- <a color="accent" type="button" class="btn btn-primary-blue" (click)="storageBuyQuoteService()">
        {{idServiceOrder == undefined? 'Abrir OS': 'Continuar orçamento'}}
      </a> -->
      <a type="button" class="btn btn-secondary-outline" (click)="storageBuy()">
        Comprar
      </a>
    </div>

    <div class="shopping-card-small">
      <div class="shopping-card-small-content">
        <!-- <a color="accent" type="button" class="btn btn-primary-blue" (click)="storageBuyQuoteService()"
          *ngIf="searchShoppingCartStuct.searchShoppingCarItemStruct.length != 0">
          Abrir OS
        </a> -->
        <a mat-flat-button class="btn btn-small" (click)="storageBuy()"
          *ngIf="searchShoppingCartStuct.searchShoppingCarItemStruct.length != 0">
          Comprar
        </a>
        <p *ngIf="searchShoppingCartStuct.searchShoppingCarItemStruct.length != 1">
          {{searchShoppingCartStuct.searchShoppingCarItemStruct.length}} itens no carrinho</p>
        <p *ngIf="searchShoppingCartStuct.searchShoppingCarItemStruct.length == 1">
          {{searchShoppingCartStuct.searchShoppingCarItemStruct.length}} item no carrinho</p>
      </div>
    </div>
  </div>

  <div class="shopping-card-item" *ngFor="let item of searchShoppingCartStuct.searchShoppingCarItemStruct">
    <span class="name">{{item.partProductStruct.productName}}</span>
    <span class="brand">{{item.partProductStruct.brandName}} {{item.partProductStruct.partNumber}}</span>
    <div class="item-row">
      <div class="item-col-2">
        <span class="supplier">{{item.partProductStruct.supplierName}}</span>
      </div>
      <div class="item-col-2">
        <span class="price"> {{item.partProductStruct.price | currency: 'BRL'}}</span>
      </div>
    </div>

    <div class="item-row">
      <div class="item-col-2">
        <span>Quantidade</span>
      </div>
      <!-- <div class="item-col-2">
                <select (change)="changeAmount($event.target.value, item)">
                    <option [selected]="item.amount === 1" value="1">1</option>
                    <option [selected]="item.amount === 2" value="2">2</option>
                    <option [selected]="item.amount === 3" value="3">3</option>
                    <option [selected]="item.amount === 4" value="4">4</option>
                    <option [selected]="item.amount === 5" value="5">5</option>
                </select>
            </div> -->
      <div class="item-col-2">
        <select (change)="changeAmount($event.target.value, item)">
          <option *ngFor="let amount of generateAmountOptions(item.partProductStruct.quantity)"
            [selected]="item.amount == amount" [value]="amount">{{ amount }}</option>
        </select>
      </div>

    </div>
    <div class="text-right">
      <a class="remove-item" aria-label="Remover" (click)="removeItemClick(item.partProductStruct.idPart)">
        <mat-icon>delete</mat-icon>
      </a>
    </div>
  </div>
</div>
