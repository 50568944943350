<app-menu [menuModuleEnum]="menuModuleEnum" [menuFunctionalityEnum]="menuFunctionalityEnum" [hasSecondaryHeader]=false>
</app-menu>
<div class="spinner" *ngIf="!modalAberto">
  <mat-progress-spinner *ngIf="loading" mode="indeterminate"></mat-progress-spinner>
</div>
<div class="container-body" *ngIf="loadedData">

  <div class="col-md-3">
    <button class="button-back" (click)="backPage()">
      <mat-icon class="icon-back">keyboard_arrow_left</mat-icon>
      <span>Voltar</span>
    </button>
  </div>
  
  <div class="budget row">
    <div class="col-md-5">
      <h2>
        Compra #{{array?.idPurchase}} - {{array?.datetimeInclusion | date: 'd MMMM y, HH:mm'}}
      </h2>
    </div>
    <div class="button-actions col-md-7">
      <tr>
        <td>
          <button mat-button id="button" (click)="captureScreen()">Imprimir</button>
        </td>
        <!-- <td>
          <button mat-button id="button">Solicitar Pagamento</button>
        </td>
        <td>
          <button mat-button id="button">Emitir NF</button>
        </td> -->
      </tr>
    </div>
  </div>
  <div class="row">
    <div class="col-md-5">
      <h2>
        Comprador: {{array?.userName}}
      </h2>
    </div>
  </div>
  <div class="details-budget">
    <tr class="tr-budget">
      <td class="white-budget">
        <div>
          <span>Peças</span>
          <h2>{{getPurchaseHistoryDetailsResponse?.purchaseDeatailsStruct?.totalPartsPrice | currency: 'BRL'}}</h2>
        </div>
      </td>
      <td class="white-budget">
        <div>
          <span>Entrega</span>
          <h2>{{getPurchaseHistoryDetailsResponse?.purchaseDeatailsStruct?.totalDeliveryFee | currency: 'BRL'}}</h2>
        </div>
      </td>
      <td class="white-budget-last">
        <div>
          <span>Total</span>
          <h2>{{getPurchaseHistoryDetailsResponse?.purchaseDeatailsStruct?.totalPrice | currency: 'BRL'}}</h2>
        </div>
      </td>
    </tr>
  </div>
  <div class="white-body table-responsive">
    <h2 class="header-table">Peças</h2>
    <table class="basic-table bottom">
      <thead class="basic-table">
        <tr class="truncate">
      </thead>
      <tbody class="basic-table__body">
        <tr class="basic-table__body__row" *ngFor="let item of array?.listBuyOrderPart">
          <td class="truncate">
            <img src="assets/images/no-image.png" class="img-size" alt="..." *ngIf="item.partImage64 == null">
            <img *ngIf="item.partImage64 != null" class="img-part"
              [src]="'data:image/jpg;base64,' + item.partImage64" />
          </td>
          <td class="row grid">
            <ul class="ul-format">
              <li class="title">{{item.partName}}</li>
              <li>{{item.brandName}}</li>
            </ul>
          </td>
          <td class="row grid">
            <ul class="ul-format">
              <li class="title">Preço Unitário</li>
              <li>{{item.partPrice | currency : 'BRL' }}</li>
            </ul>
          </td>
          <td class="row grid">
            <ul class="ul-format">
              <li class="title">Quantidade</li>
              <li>{{item.quantity}}</li>
            </ul>
          </td>
          <td class="row grid">
            <ul class="ul-format">
              <li class="title">Fornecedor</li>
              <li>{{ item.supplierName }}</li>
            </ul>
          </td>
          <td class="row grid">
            <ul class="ul-format">
              <li class="title">Status</li>
              <li>{{ item.buyOrderStatusName }}</li>
            </ul>
          </td>
        </tr>
      </tbody>
    </table>

    <h2 *ngIf="array?.plateStruct != null">Detalhes</h2>
    <table *ngIf="array?.plateStruct != null" class="basic-table bottom">
      <thead class="basic-table">
        <tr class="truncate">
        </tr>
      </thead>
      <tbody class="basic-table__body">
        <tr class="basic-table__body__row">
          <td class="row grid">
            <ul class="ul-format">
              <li></li>
              <li></li>
            </ul>
          </td>
          <td class="row grid">
            <ul class="ul-format">
              <li>
                <span class="span-details">
                  Comprador:
                </span>
                <span>
                  {{array?.userName}}
                </span>
              </li>
              <li>
                <span class="span-details">
                  Placa:
                </span>
                <span>
                  {{array?.plateStruct}}
                </span>
              </li>
            </ul>
          </td>
          <td class="row grid">
            <ul class="ul-format">
              <li>
                <span class="span-details">
                  Oficina:
                </span>
                <span>
                  {{array?.branchName}}
                </span>
              </li>
              <li>
                <span class="span-details">
                  <!-- Veículo: -->
                </span>
                <span>
                  <!-- Siena LX -->
                </span>
              </li>
            </ul>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="bottom">
      <h2>Entrega</h2>
      <ul class="ul-format">
        <li>
          <h3>
            Endereço da Entrega
          </h3>
        </li>
      </ul>
      <div class="delivery">
        <div class="address">
          <ul>
            <li>
              <label class="neighborhood" for="">{{array?.branchDeliveryAddress?.addressName}}</label>
            </li>
          </ul>
          <ul>
            <li>
              {{array?.branchDeliveryAddress?.street}},
              {{array?.branchDeliveryAddress?.number}} -
              {{array?.branchDeliveryAddress?.neighborhood}},
              {{array?.branchDeliveryAddress?.city}},
              {{array?.branchDeliveryAddress?.zipcode}}
            </li>
          </ul>
        </div>
      </div>
      <ul class="ul-format">
        <li>
          <h3>
            Método da Entrega
          </h3>
        </li>
      </ul>
      <div class="delivery-table">
        <table class="basic-table" *ngFor="let item of distinctSuppliers">
          <thead class="basic-table">
            <tr class="truncate">
              <th class="left-align">Fornecedor
              </th>
              <th class="left-align">Prazo da Entrega
              </th>
              <th class="left-align">Meio de Entrega
              </th>
              <th class="left-align">Valor
              </th>
            </tr>
          </thead>
          <tbody class="basic-table__body">
            <tr class="basic-table__body__row" *ngFor="let value of array?.listBuyOrderStruct">
              <td class="truncate left-align">
                <label class="label-delivery" for="">{{item.supplierName }}</label>
              </td>
              <td class="truncate left-align">
                <p *ngIf="item.idDeliveryType == 1">
                  {{ convertMinutesToHours(value.estimatedDeliveryTime) }}
                </p>                
                <p *ngIf="item.idDeliveryType == 2">Sem Estimativa</p>
                <p *ngIf="item.idDeliveryType == 3">-</p>
              </td>
              <td class="truncate left-align" *ngIf="item.idDeliveryType == 1">
                <p>Entrega Meros</p>
              </td>
              <td class="truncate left-align" *ngIf="item.idDeliveryType == 2">
                <p>Entrega do Fornecedor</p>
              </td>
              <td class="truncate left-align" *ngIf="item.idDeliveryType == 3">
                <p>Retirada</p>
              </td>
              <td class="truncate left-align">
                {{ value.estimatedDeliveryFee | currency: 'BRL' }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <ul class="ul-format" *ngIf="array?.plateStruct != null">Detalhes>
        <li>
          <h3>
            Contato de entrega
          </h3>
        </li>
      </ul>

      <ul class="ul-format" *ngIf="array?.plateStruct != null">Detalhes>
        <li>Nome: {{this.name}}</li>
        <li>Telefone: {{this.phone}}</li>
      </ul>
      <ul></ul>
    </div>

    <div *ngFor="let item of distinctSuppliers">
      <ng-container *ngIf="item.idDeliveryType === 1">
        <h2>Pagamento</h2>
        <ul class="ul-format">
          <li>
            O Pagamento do produto será efetuada pela plataforma
          </li>
        </ul>
        <ul class="ul-format">
          <li>
            O Pagamento da Entrega Meros é feito Separadamente
          </li>
        </ul>
        <div class="delivery-table">
          <table class="basic-table">
            <thead class="basic-table">
              <tr class="truncate">
                <th class="left-align">Cartão de Crédito/Débito
                </th>
                <th class="left-align">Nome do Cartão
                </th>
                <th class="left-align">Vencimento
                </th>
            </thead>
            <tbody class="basic-table__body">
              <tr class="basic-table__body__row">
                <td class="truncate left-align">
                  <mat-icon>credit_card</mat-icon>
                  <label for="">Cartão terminado em {{array?.branchCreditCard?.creditCardDigits}}</label>
                </td>
                <td class="truncate left-align">
                  {{array?.branchCreditCard?.creditCardName}}
                </td>
                <td class="truncate left-align">
                  {{array?.branchCreditCard?.expirationMonth}}/{{array?.branchCreditCard?.expirationYear}}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ng-container>
    </div>
  </div>
</div>
