import { PurchaseSupplierStruct } from "../../structs/orchestrator-purchase/purchase-supplier.struct";
import { PartProductStruct } from "../../structs/autoparts/part-product.struct";

export class FinishPurchaseRequest{
    public idQuoteService: number;
    public idBranchCreditCard: number;
    public idBranchDeliveryAddress: number;
    public isValidated: boolean;
    public totalValue: number;
    public deliveryPrice: number;
    public recieverName: string;
    public recieverPhone: string;
    
    public listSupplier : PurchaseSupplierStruct[];
    
    public listParts: PartProductStruct[];
}