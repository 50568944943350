import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {PartProductStruct} from 'src/app/shared/services/API/structs/autoparts/part-product.struct';

@Component({
  selector: 'app-search-list-item',
  templateUrl: './search-list-item.component.html',
  styleUrls: ['./search-list-item.component.css']
})
export class SearchListItemComponent implements OnInit {

  constructor(private formBuilder: FormBuilder) {

  }

  public model: FormGroup;
  @Input() partProductStruct: PartProductStruct;
  @Output() addItem = new EventEmitter<number>();
  public maxAmount: number;
  public quantity: any
  public collectedParts: PartProductStruct[] = [];
  public filteredParts: PartProductStruct[] = [];

  ngOnInit(): void {

    this.model = this.formBuilder.group({
      amount: [1],
    });

    this.quantity = this.partProductStruct.supplierInternalCode


    this.maxAmount = this.quantity.reduce((max, supplier) => {
      return Math.max(max, supplier.quantity || 0);
    }, 0);
    // console.log(this.partProductStruct)
  }

  applyFilter(item: any) {
    if (item.length > 0) {
      this.filteredParts = this.collectedParts.filter(part => item.includes(part.brandName));
    } else {
      this.filteredParts = [...this.collectedParts]; // Se nenhum filtro estiver selecionado, exibe todos os itens
    }
  }


  generateAmountOptions(): number[] {
    return Array.from({length: this.maxAmount}, (_, i) => i + 1);
  }

  clickAddItem() {
    const amount = this.model.get("amount").value;
    this.addItem.emit(amount);
  }
}
