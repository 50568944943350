<div class="list-item">
  <div class="item-img">
    <div class="vertical-aling-out">
      <div class="vertical-aling-inner">
        <img *ngIf="partProductStruct.partImage64 !== null"
          src="{{'data:image/jpg;base64,' + partProductStruct.partImage64}}" />
        <img *ngIf="partProductStruct.partImage64 === null" src="/assets/images/no-image.png" />
      </div>
    </div>
  </div>
  <div class="item-content">
    <span class="price">{{partProductStruct.priceInvoice | currency: 'BRL'}} </span>
    <span *ngIf="partProductStruct.productName != null" class="name"> {{partProductStruct.productName}}</span>
    <span *ngIf="partProductStruct.productName == null" class="name"> {{partProductStruct.supplierPartDescription}}</span>
    <span *ngIf="partProductStruct.partNumber != null" class="brand">{{partProductStruct.brandName}} - {{partProductStruct.partNumber}}</span>
    <span *ngIf="partProductStruct.partNumber == null" class="brand">{{partProductStruct.brandName}} - {{partProductStruct.supplierPartNumber}}</span>
    <span class="supplier"><b>Fornecedor:</b> {{partProductStruct.supplierName}}</span>
    <form class="form" [formGroup]="model" (ngSubmit)="clickAddItem()">
      <div class="amount">
        <span>Quantidade</span>
        <select formControlName="amount">
          <option *ngFor="let amount of generateAmountOptions()" [value]="amount">{{ amount }}</option>
        </select>
      </div>
      <a color="accent" type="button" class="btn btn-primary-blue" (click)="clickAddItem()">
        Adicionar ao carrinho
      </a>
    </form>
  </div>
</div>
