import { Component, OnDestroy, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { FormGroup, FormBuilder, FormsModule } from '@angular/forms';
import { GetInfosResponse } from 'src/app/shared/services/API/responses/autoparts/Vehicle/get-infos.response';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ModalChangeVehicleComponent } from '../search/search-header/modal-change-vehicle/modal-change-vehicle.component';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})
export class ProductComponent implements OnInit {

  constructor(private formBuilder: FormBuilder,
    public dialog: MatDialog){
  }
  
  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.oficina;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.oficina_dashboard;
  public model: FormGroup;
  public FormsModule: FormsModule;
  public setAplication: boolean = true;
  public setSpecification: boolean = false;
  public setSimilares: boolean = false;
  
  Aplication = [
    {Montadora: 'FORD', Veiculo: 'Focus', Modelo: 'GL', Motor: 'ZETEC ROCAM', ConfigMotor: '1.6 L 8V SOHC L4', Inicio: '2007', Fim: '2009'}
  ]
  
  ngOnInit(): void {
   this.PopulatePage()
  }

  PopulatePage(){
    this.Aplication
  }

  setIsAplication(){
    this.setAplication = true;
    this.setSpecification = false;
    this.setSimilares = false;
  }

  setIsSpecification(){
    this.setSpecification = true;
    this.setAplication = false;
    this.setSimilares = false;
  }

  setIsSimilares(){
    this.setSpecification = false;
    this.setAplication = false;
    this.setSimilares = true;
  }

  @Output() OutputClickFilter = new EventEmitter<any>();
  @Output() SearchClick = new EventEmitter<any>();

  @Input() idServiceOrder:number;
  
  @Input() modelSearch:FormGroup; 
  @Input() getInfosResponse:GetInfosResponse;
  @Input() year:GetInfosResponse;
  @Input() plateNumber:GetInfosResponse;
  
  clickFilter(){
    this.OutputClickFilter.emit();
  }

  seach(){
    this.SearchClick.emit();
  }

  openModalChengeVehicle(){
    const dialogRef = this.dialog.open(ModalChangeVehicleComponent, {
      data: {
       
      },
    })
    dialogRef.afterClosed().subscribe({
      next: result => {
        
      }
    });
  }

}