<app-menu #menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum' [hasSecondaryHeader]=false></app-menu>
<div i18n class="container-body">
    <div class="details-buy-mobile">
        <a mat-flat-button type="button" class="btn btn-block" (click)="goBack()">
            <mat-icon matPrefix class="my-icon material-symbols-outlined">arrow_back</mat-icon>
            <span *ngIf="!isLoading" class="next-btn">Anterior</span>
            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
        </a>
        <div class="detail-price">
            <span>Total</span>
            <span class="price-tag-mobile">{{this.totalPrice | currency: 'BRL'}}</span>
        </div>
        <a mat-flat-button type="button" class="btn btn-block" color="acent" routerLink="/workshop/purchase" [disabled]="totalItems === 0">
            <span *ngIf="!isLoading" class="next-btn">Finalizar Pedido</span>
            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
        </a>
    </div>
    <div class="row">
        <div class="col-12 col-md-8 cart-section">
            <h1 class="cart-title">CARRINHO</h1>
            <div *ngFor="let item of listParts">
                <div class="row part-item-div">
                    <div class="col-12 col-sm-3 img-div">
                        <img class="img-part" [src]="'data:image/jpg;base64,' + item.partProductStruct.partImage64"/>
                    </div>
                    <div class="col-12 col-sm-9">
                        <div class="row">
                            <div class="col-12 col-sm-5">
                                <div class="part-item-title">{{item.partProductStruct.productName}}</div>
                                <div>{{item.partProductStruct.brandName}} | {{item.partProductStruct.partNumber}}</div>
                            </div>
                            <div class="col-12 col-sm-4">
                                <div class="part-item-title">Preço Unidade</div>
                                <div>{{item.partProductStruct.price | currency: 'BRL'}}</div>
                            </div>
                            <div class="col-12 col-sm-3">
                                <div class="part-item-title">Quantidade</div>
                                <div>
                                    <select #quantity (change)="changeAmount(quantity.value, item)">
                                        <option *ngFor="let amount of generateAmountOptions(item.partProductStruct.quantity)" [selected]="item.amount === amount" [value]="amount">{{ amount }}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="mid-section">
                            <span>Fornecedor: </span>
                            <span>{{item.partProductStruct.supplierName ? item.partProductStruct.supplierName : '-'}}</span>
                        </div>
                        <div class="row">
                            <div class="col-12 col-sm-3">
                                <a (click)="removeFromCart(item)" style="color: red;" class="action-link">Remover</a>
                            </div>
                            <div class="col-12 col-sm-5">
                                <a (click)="saveForLater(item)" style="color: #001f38;" class="action-link">Salvar para depois</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="totals">
                <span>{{totalItems}} produto(s) -----------</span>
                <span class="price-tag">{{this.totalPrice | currency: 'BRL'}}</span>
            </div>
        </div>
        <div class="col-4 details">
            <h1 class="details-card-title">DETALHES</h1>
            <div class="details-lable-div">
                <span class="span-lable">Comprador</span>
                <span>{{userStruct.userName}}</span>
            </div>
            <div class="details-lable-div">
                <span class="span-lable">Oficina</span>
                <span>{{userStruct.branch.branchName}}</span>
            </div>
            <hr>
            <div class="detail-price">
                <span>Total -------</span>
                <span class="price-tag">{{this.totalPrice | currency: 'BRL'}}</span>
            </div>
            <div class="row action-buttons">
                <div class="col-5">
                    <button mat-flat-button type="button" class="btn btn-block" color="accent" (click)="goBack()">
                        <span *ngIf="!isLoading" class="next-btn">
                            <mat-icon matPrefix class="my-icon material-symbols-outlined">arrow_back</mat-icon>
                            Anterior</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                    </button>
                </div>
                <div class="col-7">
                    <a mat-flat-button type="button" class="btn btn-block" color="primary" routerLink="/workshop/purchase" [disabled]="totalItems === 0">
                        <span *ngIf="!isLoading" class="next-btn">Finalizar Pedido</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
